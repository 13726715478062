<template>
  <div class="flats-page" :class="{withTopBar: isTopBar}">
    <HomeMap />
    <section class="flats" id="tabulka">
      <FlatsTable :show-vr="true"></FlatsTable>
    </section>
  </div>
</template>

<script>

import FlatsTable from "@/components/FlatsTable";
import HomeMap from "@/components/maps/HomeMapNew";

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
  name: "Flats",
  components: {HomeMap, FlatsTable},
  data() {
    return {
      isTopBar: true
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
    this.isTopBar = getCookie('hideTopBar') == 1 ? false : true
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.flats-page
  padding-top: 85px
  background: #2D323A

  &.withTopBar
    padding-top: 127px

  @media (max-width: $md)
    padding-top: 45px

</style>
