import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=c5d24886&scoped=true"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"
import style0 from "./Contact.vue?vue&type=style&index=0&id=c5d24886&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d24886",
  null
  
)

export default component.exports