<template>
  <div>
    <div class="fixed-bar d-flex align-items-center justify-content-center px-5 px-md-0" v-if="showTopBar">
      <a href="/byty" class="mb-0">Ceny bytov sú uvedené vrátane DPH platnej len do konca roka 2024</a>
      <b-link class="ml-5 close-btn" @click="hideTopBar()"><img width="14" src="../assets/icons/close-gallery.svg" alt=""></b-link>
    </div>
    <b-navbar id="navBar" fixed="top" toggleable="xl" class="py-md-0 px-md-5 bory-navbar" type="dark" :class="{navbarbg: scrollPosition > 1 || sidebarVisible, withTopBar: showTopBar}">
      <b-navbar-brand to="/" class="d-flex align-items-center p-0 mr-md-4" @click="scrollToTop">
        <div class="logo mr-3">
          <img src="../assets/images/bory-logo.png" class="d-inline-block align-top" alt="Bory byvanie">
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">Menu</b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="">
        <b-navbar-nav class="menu-center">
          <b-nav-item to="/byty">Byty</b-nav-item>
          <b-nav-item to="/novinky/ako-financovat-vase-byvanie-na-boroch">Financovanie</b-nav-item>
          <b-nav-item to="/ua-financovanie">фінансування <img class="ml-2" src="../assets/images/ukraine.png" height="18" alt=""></b-nav-item>
          <b-nav-item to="/znizeny-urok">Znížený úrok</b-nav-item>
          <div class="d-block d-xl-none">
            <b-nav-item to="/nasa-stvrt">Štvrť</b-nav-item>
            <b-nav-item to="/novinky">Novinky</b-nav-item>
            <b-nav-item to="/zaluzie-a-kuchyna-v-cene-bytu">Kuchyňa a žalúzie v cene bytu</b-nav-item>
            <b-nav-item to="/galeria">Galéria</b-nav-item>
            <b-nav-item to="/retail-zone">Nákupná zóna</b-nav-item>
            <b-nav-item to="/kontakt">Kontakty</b-nav-item>
            <b-button class="d-block mx-auto" @click="scrollToContact" squared variant="primary" v-b-toggle.nav-collapse>Mám záujem</b-button>
          </div>
        </b-navbar-nav>
        
        <b-navbar-nav class="ml-auto d-flex align-items-center">
          <!-- <b-link class="search" v-b-toggle.sidebar-2.nav-collapse><img src="../assets/images/search-icon.svg">Nájsť byt</b-link> -->
          <!-- <b-nav-item-dropdown text="SK" right class="mr-4 lang-dropdown">
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">UA</b-dropdown-item>
            <b-dropdown-item href="#">SK</b-dropdown-item>
          </b-nav-item-dropdown> -->
          <b-button class="mr-xl-5 d-none d-xl-flex" @click="scrollToContact" squared variant="primary" v-b-toggle.nav-collapse>Mám záujem</b-button>
          <b-link class="menu d-none d-xl-flex" v-b-toggle.sidebar-3>
            <div class="menu-btn-3" :class="{'active': burgerClassActive}">
                <span></span>
            </div>  
            Menu
          </b-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar ref="sidebar3" id="sidebar-3" class="sidebar" bg-variant="dark" text-variant="light" :no-header-close="true" right v-on:change="sidebarClass">
      <div class="menu-wrapper">
        <b-nav-item to="/nasa-stvrt">Štvrť</b-nav-item>
        <b-nav-item to="/novinky">Novinky</b-nav-item>
        <b-nav-item to="/zaluzie-a-kuchyna-v-cene-bytu">Kuchyňa a žalúzie v cene bytu</b-nav-item>
        <b-nav-item to="/galeria">Galéria</b-nav-item>
        <b-nav-item to="/retail-zone">Nákupná zóna</b-nav-item>
        <b-nav-item to="/kontakt">Kontakty</b-nav-item>

        <p class="my-5"><a href="https://www.facebook.com/Borybyvanie/" target="_blank" class="d-flex align-items-center"><img class="mr-2" src="../assets/images/facebook-icon.svg"> Facebook</a></p>

        <p class="m-0">Jana Miková</p>
        <p class="m-0 mb-3"><a href="tel:+421910612765">+421 910 612 765</a></p>

        <p class="m-0">Kamila Kordošová</p>
        <p class="m-0 mb-3"><a href="tel:+421903590023">+421 903 590 023</a></p>

        <p class="m-0">Zuzana Bábelová</p>
        <p class="mb-5"><a href="tel:+421904722539">+421 904 722 539</a></p>

        <p class="mt-4"><a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>

      </div>
    </b-sidebar>
    <div class="sidebar-button" v-if="!this.sidebarVisible" v-b-toggle.sidebar-1><img src="../assets/images/close-sidebar-icon.svg"><a>Všetky etapy Bory Bývania</a></div>
    <b-sidebar ref="sidebar" @mouseleave.native="hideSidebar" id="sidebar-1" class="sidebar" v-on:change="visible" bg-variant="dark" text-variant="light" right>
      <div class="close-sidebar" v-b-toggle.sidebar-1><img src="../assets/images/close-sidebar-icon.svg"></div>
      <div>
        <h3>Etapy projektu Bory Bývanie</h3>
        <div class="project">
          <b-link to="/byvanie/na-hradzi" class="detail-link"></b-link>
          <img src="../assets/images/nahradzi.jpg" alt="Bory Na Hrádzi">
          <p>Bory Na Hrádzi</p>
        </div>
        <div class="project">
          <b-link to="/byvanie/bory3" class="detail-link"></b-link>
          <img src="../assets/images/bory3/bory3.jpg" alt="Bory Promenáda">
          <p>Bory Promenáda</p>
        </div>
        <div class="project">
          <router-link to="/byvanie/novy-dvor" class="detail-link"></router-link>
          <img src="../assets/images/bory_lake2.jpg" alt="Bory Bývanie 2">
          <p>Bory Nový Dvor</p>
        </div>
        <div class="buttons mt-5">
          <b-button v-b-toggle.sidebar-1 @click="scrollToContact" block squared variant="primary">Mám záujem</b-button>
          <b-button to="/nasa-stvrt" block squared variant="outline-primary">Viac o štvrti</b-button>
        </div>
      </div>
    </b-sidebar>
    <b-sidebar ref="sidebar2" @mouseleave.native="hideSidebar" id="sidebar-2" class="sidebar" v-on:change="visible" bg-variant="dark" text-variant="light" right>
      <div class="close-sidebar" v-b-toggle.sidebar-2><img src="../assets/images/close-sidebar-icon.svg"></div>
      <FlatsFilter v-model="flatsFilter" :sideMenu="true" />
      <b-button block squared variant="primary" class="mt-5" @click="navToFlatList">Hľadať</b-button>
    </b-sidebar>
  </div>
</template>

<script>
import FlatsFilter from '@/components/FlatsFilter.vue'

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export default {
  name: "Header",
  components: {
    FlatsFilter
  },

  data() {
    return {
      burgerClassActive: false,
      flatsFilter: {
        stages: [],
        dates: [],
        buildings: [],
        rooms: [],
        floors: [],
        availability: 0,
        priceVat: null,
        priceVatMin: 0,
        priceVatMax: 500000,
        totalArea: null
      },
      sidebarVisible: '',
      scrollPosition: null,
      showTopBar: true
    }
  },
  methods: {
    sidebarClass(visible) {
      this.burgerClassActive = !this.$refs.sidebar3.isOpen
    },
    scrollToTop() {
      if (this.$route.name == 'Home') {
        document.documentElement.scrollIntoView({ behavior: 'smooth' })
      }
    },
    hideSidebar() {
      this.sidebarVisible = false
      this.$refs.sidebar.hide()
      this.$refs.sidebar2.hide()
    },
    scrollToContact() {
      const contactForm = document.getElementById('contact-form')
      if (contactForm) {
        contactForm.scrollIntoView({behavior: "smooth", block: 'center'})
      } else {
        this.$router.push({ path: 'kontakt#contact-form' })
      }

    },
    hideTopBar() {
      setCookie('hideTopBar', '1', 14)
      this.showTopBar = false
    },
    visible(event) {
      this.sidebarVisible = event
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    navToFlatList() {
      this.$router.push({
        name: 'Flats',
        hash: '#tabulka',
        query: { filter: JSON.stringify(this.flatsFilter) }
      })
      this.flatsFilter = {
        stages: [],
        dates: [],
        buildings: [],
        rooms: [],
        floors: [],
        availability: 'all',
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    this.showTopBar = getCookie('hideTopBar') != 1 ? true : false
  }
}
</script>

<style lang="sass" scoped>
  $md: 768px
  $lg: 1200px

  .fixed-bar
    position: fixed
    top: 0
    left: 0
    text-align: center
    background: var(--primary)
    width: 100%
    padding: 10px
    z-index: 9999999
    
    a
      font-weight: bold
      color: #fff
      font-size: 18px

    a.close-btn
      line-height: 14px
      position: absolute
      right: 25px



  .menu-btn-3
    height: 32px
    width: 40px
    cursor: pointer
    margin-bottom: 3px
  
  .menu-btn-3 span,
  .menu-btn-3 span::before,
  .menu-btn-3 span::after 
    background: #fff
    content: ''
    position: absolute
    width: 40px
    height: 4px     
    margin-top: 13px
    
    -webkit-transform: rotate(180deg)
    -moz-transform: rotate(180deg)
    -o-transform: rotate(deg)
    transform: rotate(180deg)

    -webkit-transition: .5s ease-in-out
    -moz-transition: .5s ease-in-out
    -o-transition: .5s ease-in-out
    transition: .5s ease-in-out

  .menu-btn-3 span::before 
    margin-top: -12px
  

  .menu-btn-3 span::after 
    margin-top: 12px
  

  .menu-btn-3.active span 
    background: transparent
  

  .menu-btn-3.active span::before
    margin-top: 0
    -webkit-transform: rotate(45deg)
    -moz-transform: rotate(45deg)
    -o-transform: rotate(45deg)
    transform: rotate(45deg)

  .menu-btn-3.active span::after
    transform: rotate(-45deg)
    margin-top: 0
  

  .menu-center
    position: absolute
    left: 50%
    transform: translateX(-50%)

    @media (max-width: $lg)
      position: relative
      left: auto
      transform: none

  .menu-wrapper
    padding: 30px 45px
    li
      list-style: none
      margin-bottom: 10px

      a
        color: #fff
        font-weight: 700
        font-size: 18px
        padding: 0

        &:hover
          color: var(--blue)

    a
      color: var(--blue)

    p
      font-size: 16px
  .bory-navbar
    &.withTopBar
      top: 42px

      @media (max-width: $md)
        top: 56p
    @media (max-width: $lg)
      padding-top: 8px !important
      padding-bottom: 12px !important
      background: rgba(33,37,43,0.9)
      .logo-text
        padding-left: 75px

    .logo
      img
        max-height: 85px

        @media (max-width: $lg)
          max-height: 42px
  .navbarbg
    background: rgba(33,37,43,0.9)
  .sidebar-button
    position: fixed
    right: -88px
    top: 50%
    margin-top: -23px
    background: rgba(33,37,43,0.9)
    padding: 10px 15px
    transform: rotate(90deg)
    outline: none
    z-index: 9999
    img
      position: absolute
      transform: rotate(90deg) translateY(25%)
      left: 50%
      bottom: -15px
    &:before
      content: ""
      border-bottom-left-radius: 50px
      border-bottom-right-radius: 50px
      height: 27px
      width: 50px
      position: absolute
      background: rgba(33,37,43,0.9)
      left: 50%
      margin-left: -25px
      bottom: 0
      margin-bottom: -27px
      z-index: -2
    a
      color: #fff
      font-size: 14px
      font-weight: 500
      text-decoration: none
  .sidebar
    position: relative
    .close-sidebar
      position: absolute
      width: 50px
      height: 50px
      left: -25px
      top: 50%
      margin-top: -25px
      border-radius: 50%
      z-index: 9999
      display: flex
      align-items: center
      justify-content: center
      outline: none
      @media (max-width: $md)
        left: 0
      &:before
        content: ""
        border-bottom-left-radius: 50px
        border-top-left-radius: 50px
        height: 50px
        width: 27px
        position: absolute
        background: rgba(33,37,43,0.9)
        left: -2px
        top: 50%
        margin-top: -25px
        z-index: -2
        @media (max-width: $md)
          background: transparent
    .btn
      font-weight: 500
    .btn-outline-primary
      color: #fff
    h3
      font-size: 20px
      font-weight: 500
      margin-bottom: 26px
      margin-top: 30px
    .project
      position: relative
      .detail-link
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
      p
        margin: 15px 0 20px
        font-weight: 500
    img
      max-width: 100%
  .navbar-dark
    z-index: 9999
    .navbar-brand
      font-weight: 800
      font-size: 32px
      letter-spacing: -1.5px
      @media (max-width: $md)
        font-size: 26px
        line-height: 27px
        letter-spacing: -1px
    .navbar-nav
      .nav-item
        margin: 0 20px
      .nav-link
        color: #fff
        font-weight: bold
      .router-link-active
        border-bottom: 1px solid #fff
    .search
      color: #fff
      font-size: 18px
      font-weight: 500
      margin-right: 38px
      img
        margin-right: 10px
    .menu
      color: #fff
      font-size: 16px
      font-weight: 700
      display: flex
      flex-direction: column
      align-items: center
      line-height: 1

      &:hover
        text-decoration: none
    .side-menu-top
      margin-left: 36px
      cursor: pointer
</style>
