<template>
    <div class="exit-modal" v-if="showModal">
        <div class="close" @click="closeExitModal">
            <img src="../assets/icons/close-retail.png" alt="">
        </div>
        <div>
            <div class="d-flex">
                <div class="col-6 col-left">
                    <h2>Neviete sa rozhodnúť?</h2>
                    <p>Vieme, že nájsť nový domov nie je ľahké. Radi Vám pomôžeme a&nbsp;zodpovieme všetky Vaše otázky.</p>
                    <div class="form" v-if="!sent">
                        <h5 class="font-weight-bold">
                            Môžeme si zavolať?
                        </h5>
                        <VuePhoneNumberInput 
                            v-model="vuePhone.phone" 
                            size="lg" 
                            v-bind="vuePhone.props"
                            @update="onUpdate"
                        />
                        <p class="text-danger" v-if="error">Prosím zadajte Vaše tel. číslo</p>
                        <button class="btn btn-primary rounded-0" @click="sendForm">Odoslať</button>  
                    </div>
                    <div v-else>
                        <h5 class="text-blue">Ďakujeme! Budeme Vás kontaktovať.</h5>
                    </div>
                </div>
                <div class="col-6 pr-0">
                    <img src="../assets/images/bory-exit-modal.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import VuePhoneNumberInput from 'vue-phone-number-input';
import cookies from '@/plugins/cookie-banner/getCookie'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { useVuelidate } from '@vuelidate/core';
function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export default {
    setup: () => ({ v$: useVuelidate() }),
    components: { VuePhoneNumberInput },
    props: {
        showModal: {
            required: false,
            default: false
        }
    },
    data() {
        return {
            phone: null,
            error: false,
            sent: false,
            vuePhone: {
                phone: "",
                isValid: false,
                props: {
                    clearable: true,
                    preferredCountries: ["SK", "CZ", "AT", "PL"],
                    defaultCountryCode: "SK",
                    noCountrySelector: true,
                    noExample: false,
                    noFlags: true,
                    error: false,
                    translations: {
                        countrySelectorError: "Chyba",
                        phoneNumberLabel: "Zadajte Vaše tel. číslo",
                        example: "Napríklad:"
                    }
                }
            },
            contactForm: {
                "phone": ""
            }
        }
    },
    methods: {
        onUpdate: function(payload) {
            if (payload.isValid) {
                this.vuePhone.props.error = false
                this.vuePhone.isValid = true
            }
            this.contactForm["phone"] = payload.formattedNumber;
        },
        closeExitModal() {
            setCookie('disableExitModal', '1', 3)
            this.$emit('update-value', false)
        },
        async sendForm(e) {
            const result = await this.v$.$validate()
            if (!result || !this.vuePhone.isValid) {
                if (!this.vuePhone.isValid) {
                    this.vuePhone.props.error = true
                    this.error = true;
                    return
                }
                return
            }

            this.contactForm["first_interaction_source"] = cookies.getCookieByName("first_interaction")['utm_source']
            this.contactForm["first_interaction_medium"] = cookies.getCookieByName("first_interaction")['utm_medium']
            this.contactForm["first_interaction_campaign"] = cookies.getCookieByName("first_interaction")['utm_campaign']
            this.contactForm["first_interaction_content"] = cookies.getCookieByName("first_interaction")['utm_content']
            this.contactForm["first_interaction_gclid"] = cookies.getCookieByName("first_interaction")['gclid']
            this.contactForm["first_interaction_fbclid"] = cookies.getCookieByName("first_interaction")['fbclid']
            this.contactForm["last_interaction_source"] = cookies.getCookieByName("last_interaction")['utm_source']
            this.contactForm["last_interaction_medium"] = cookies.getCookieByName("last_interaction")['utm_medium']
            this.contactForm["last_interaction_campaign"] = cookies.getCookieByName("last_interaction")['utm_campaign']
            this.contactForm["last_interaction_content"] = cookies.getCookieByName("last_interaction")['utm_content']
            this.contactForm["last_interaction_gclid"] = cookies.getCookieByName("last_interaction")['gclid']
            this.contactForm["last_interaction_fbclid"] = cookies.getCookieByName("last_interaction")['fbclid']

            e.preventDefault()

            axios
            .post(`${process.env.VUE_APP_API_URL}/api/v1/phone-contact`, this.contactForm)
            .then((response) => {
                this.contactForm.phone = ""
                this.error = false
                this.sent = true
            }).catch((error) => {
                // handle error
                console.log(error.response.data.error);
        })
        }
    }
}


</script>

<style lang="sass" scoped>
$md: 768px
.exit-modal
    background: #fff
    box-shadow: 0px 20px 48px rgba(0, 0, 0, 0.3)
    width: 680px
    position: fixed
    z-index: 9999999
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    padding: 50px 0 50px 50px
    color: #001F33

    @media (max-width: $md)
        display: none

    .close
        position: absolute
        right: 20px
        top: 15px
        opacity: 1
        cursor: pointer

        img
            width: 20px
            display: block

    .col-left
        display: flex
        flex-direction: column
        justify-content: center

    h2
        font-weight: 800
        margin-bottom: 40px

    p
        font-size: 16px
        margin-bottom: 40px

    .text-blue
        color: var(--blue)

    input
        border: none
        outline: none
        border-bottom: 1px solid var(--grey)
        width: 100%
        padding: 8px 0
        margin-bottom: 20px

        &::placeholder
            color: var(--grey)
</style>