<template>
  <div>
      <div class="page-not-found-page text-center">
          <div class="container">
              <div class="row">
                  <div class="col-12 my-5">
                    <h3 class="mb-5">Stránka nebola nájdená</h3>
                    <h2 class="mb-5">404</h2>
                    <h3 class="mb-5">Ospravedlňuejme sa, táto stránka zrejme neexistuje.</h3>
                  </div>
              </div>
          </div>
      </div>
      <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.page-not-found-page
    padding-top: 85px
    background: #2D323A

    h2
        font-size: 140px
        @media (max-width: 768px)
            font-size: 30px

    h3
        font-size: 22px

    p
        font-size: 16px
        font-weight: 400

    a 
        color: var(--blue)
</style>
